/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React from 'react';
import { matchPath } from 'react-router-dom';
import { Link as RouterLink, useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  //Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
//import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Briefcase as BriefcaseIcon,
  //Calendar as CalendarIcon,
  // ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  //Lock as LockIcon,
  //UserPlus as UserPlusIcon,
  //Shield as ShieldIcon,
  // AlertCircle as AlertCircleIcon,
  //Trello as TrelloIcon,
  User as UserIcon,
  //Layout as LayoutIcon,
  //Edit as EditIcon,
  // DollarSign as DollarSignIcon,
  //Mail as MailIcon,
  //MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon
  // Share2 as ShareIcon,
  // Users as UsersIcon
} from 'react-feather';
// import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import { useAuth0 } from 'src/context/auth0-context';
import { useSettings } from 'src/context/settings-context';

import { currentInstanceSelector } from 'src/features/instances/selectors';
const navConfig = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Instances',
        icon: BarChartIcon,
        href: '/app/reports/instances'
      },
      {
        // this will be replaced at the proper routes instance, users, user
        title: 'Users',
        icon: UserIcon,
        href: '/app/reports/instances/:instanceName/users',
        items: [
          {
            title: 'No Users Loaded',
            href: '/app/reports/instances/:instanceName/users/:userId'
          }
        ]
      }
    ]
  },
  {
    subheader: 'All Instances',
    items: [
      {
        title: 'All Users',
        icon: UserIcon,
        href: '/app/reports/all/users'
      }
    ]
  },
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Dashboard (example)',
        icon: PieChartIcon,
        href: '/app/reports/dashboard-example'
      },
      {
        title: 'Dashboard 2 (example)',
        icon: BarChartIcon,
        href: '/app/reports/dashboard-alternative'
      },
      {
        title: 'Documentation',
        icon: BarChartIcon,
        href: '/app/docs'
      }
    ]
  },
  /*{
    subheader: 'Management',
    items: [
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'List Customers',
            href: '/app/management/customers'
          },
          {
            title: 'View Customer',
            href: '/app/management/customers/1'
          },
          {
            title: 'Edit Customer',
            href: '/app/management/customers/1/edit'
          }
        ]
      },
      {
        title: 'Products',
        icon: ShoppingCartIcon,
        href: '/app/management/products',
        items: [
          {
            title: 'List Products',
            href: '/app/management/products'
          },
          {
            title: 'Create Product',
            href: '/app/management/products/create'
          }
        ]
      },
      {
        title: 'Orders',
        icon: FolderIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'List Orders',
            href: '/app/management/orders'
          },
          {
            title: 'View Order',
            href: '/app/management/orders/1'
          }
        ]
      },
      {
        title: 'Invoices',
        icon: ReceiptIcon,
        href: '/app/management/invoices',
        items: [
          {
            title: 'List Invoices',
            href: '/app/management/invoices'
          },
          {
            title: 'View Invoice',
            href: '/app/management/invoices/1'
          }
        ]
      }
    ]
  },*/
  {
    subheader: 'Applications',
    items: [
      {
        title: 'Data Projects Platform',
        href: '/app/data-projects',
        icon: FolderIcon,
        items: [
          {
            title: 'Overview',
            href: '/app/data-projects/overview'
          },
          {
            title: 'Browse Projects',
            href: '/app/data-projects/browse'
          },
          {
            title: 'Create Project',
            href: '/app/data-projects/create'
          },
          {
            title: 'View Project',
            href: '/app/data-projects/1'
          }
        ]
      }
      /*{
        title: 'Social Platform',
        href: '/app/social',
        icon: ShareIcon,
        items: [
          {
            title: 'Profile',
            href: '/app/social/profile'
          },
          {
            title: 'Feed',
            href: '/app/social/feed'
          }
        ]
      }*/
    ]
  },
  {
    subheader: 'Accounts',
    items: [
      {
        title: 'Accounts',
        href: '/app/accounts',
        icon: BriefcaseIcon
        /*items: [
          {
            title: 'Overview',
            href: '/app/projects/overview'
          },
          {
            title: 'Browse Projects',
            href: '/app/projects/browse'
          },
          {
            title: 'Create Project',
            href: '/app/projects/create'
          },
          {
            title: 'View Project',
            href: '/app/projects/1'
          }
        ]*/
      },
      {
        title: 'My account',
        href: '/app/account',
        icon: UserIcon
      }
      /*{
        title: 'Social Platform',
        href: '/app/social',
        icon: ShareIcon,
        items: [
          {
            title: 'Profile',
            href: '/app/social/profile'
          },
          {
            title: 'Feed',
            href: '/app/social/feed'
          }
        ]
      }*/
    ]
  }

  /*{
    subheader: 'Auth',
    items: [
      {
        title: 'Login',
        href: '/auth',
        icon: LockIcon
      }
    ]
  }, {
    subheader: 'Pages',
    href: '/app/pages',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Error',
        href: '/404',
        icon: AlertCircleIcon
      },
      {
        title: 'Pricing',
        href: '/pricing',
        icon: DollarSignIcon
      }
    ]
  }*/
  /*{
    subheader: 'Extra',
    items: [
      {
        title: 'Charts',
        href: '/app/extra/charts',
        icon: BarChartIcon,
        items: [
          {
            title: 'Apex Charts',
            href: '/app/extra/charts/apex'
          }
        ]
      },
      {
        title: 'Forms',
        href: '/app/extra/forms',
        icon: EditIcon,
        items: [
          {
            title: 'Formik',
            href: '/app/extra/forms/formik'
          },
          {
            title: 'Redux Forms',
            href: '/app/extra/forms/redux'
          }
        ]
      },
      {
        title: 'Editors',
        href: '/app/extra/editors',
        icon: LayoutIcon,
        items: [
          {
            title: 'DraftJS Editor',
            href: '/app/extra/editors/draft-js'
          },
          {
            title: 'Quill Editor',
            href: '/app/extra/editors/quill'
          }
        ]
      }
    ]
  }*/
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, ...rest }), [])}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { account } = useSelector((state) => state);
  const { user } = useAuth0();
  const { settings } = useSettings();
  // const { configs } = useConfigs();
  const history = useHistory();
  const {
    //currentPageIssues,
    isLoading,
    instancesError,
    instancesById
  } = useSelector((state) => state.instances);
  const currentInstance = useSelector(currentInstanceSelector);
  const instanceMatch = useRouteMatch('/app/reports/instances/:instanceName');
  const usersMatch = useRouteMatch('/app/reports/instances/:instanceName/users');
  const userMatch = useRouteMatch('/app/reports/instances/:instanceName/users/:userId');
  // const preventDefault = event => event.preventDefault();

  React.useEffect(() => {
    //const usersMatchExact = usersMatch?.isExact ?? usersMatch;
    if (instancesById && !isLoading && !instancesError) {
      const instanceMatchExact = instanceMatch?.isExact ?? instanceMatch;
      const usersMatchExact = usersMatch?.isExact ?? usersMatch;
      const userMatchExact = userMatch?.isExact ?? userMatch;

      if ((instanceMatchExact || usersMatchExact || userMatchExact) && currentInstance?.id) {
        const users = currentInstance?.userTotals?.userActivity;
        if (users) {
          // Add users of instance
          navConfig[0].items[1] = {
            title: 'Users',
            icon: UserIcon,
            href: `/app/reports/instances/${currentInstance?.instanceName}/users`,
            items: users.map(({ userName, userId }) => ({
              title: userName,
              href: `/app/reports/instances/${currentInstance?.instanceName}/users/${userId}`
            }))
          };
        }
      }
    }
  }, [
    history,
    pathname,
    instanceMatch,
    usersMatch,
    userMatch,
    instancesById,
    isLoading,
    instancesError,
    currentInstance
  ]);

  React.useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user?.picture ? user.picture : account.user.avatar}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user?.name ? user?.name : account?.user?.firstName} ${
                user?.name ? '' : account?.user?.lastName
              }`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              Super Admin
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => {
            if (
              settings.hideUnusedLinks &&
              ['Management', 'Pages', 'Extra', 'Dashboard'].includes(config.subheader)
            ) {
              return null;
            } else {
              return (
                <List
                  key={config.subheader}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {config.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: config.items,
                    pathname: pathname
                  })}
                </List>
              );
            }
          })}
        </Box>
        <Divider />
        {/*<Box p={2}>
          <a
            aria-current="page"
            className="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-buttonLeaf-258 depth-0 makeStyles-active-261"
            tabIndex="0"
            role="button"
            aria-disabled="false"
            href={`${window.location.origin.replace(':3000', '')}${
              configs?.isDev ? `:${configs?.serverPort}` : ''
            }/docs/client`}
            style={{ paddingLeft: '8px' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="MuiButton-label">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="makeStyles-icon-259"
              >
                <line x1="12" y1="20" x2="12" y2="10"></line>
                <line x1="18" y1="20" x2="18" y2="4"></line>
                <line x1="6" y1="20" x2="6" y2="16"></line>
              </svg>
              <span className="makeStyles-title-260">Client Code Docs</span>
            </span>
            <span className="MuiTouchRipple-root"></span>
          </a>
        </Box>
        <Divider />
        <Box p={2}>
          <a
            aria-current="page"
            className="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-buttonLeaf-258 depth-0 makeStyles-active-261"
            tabIndex="0"
            role="button"
            aria-disabled="false"
            href={`${window.location.origin.replace(':3000', '')}${
              configs?.isDev ? `:${configs?.serverPort}` : ''
            }/docs/server`}
            style={{ paddingLeft: '8px' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="MuiButton-label">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="makeStyles-icon-259"
              >
                <line x1="12" y1="20" x2="12" y2="10"></line>
                <line x1="18" y1="20" x2="18" y2="4"></line>
                <line x1="6" y1="20" x2="6" y2="16"></line>
              </svg>
              <span className="makeStyles-title-260">Server Code Docs</span>
            </span>
            <span className="MuiTouchRipple-root"></span>
          </a>
        </Box>*/}
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link variant="subtitle1" color="secondary" component={RouterLink} to="/docs">
              Documentation
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
