const isDev = process.env.NODE_ENV === 'development';
const isProd = process.env.NODE_ENV === 'production';
const isProdLocal = process.env.NODE_ENV === 'production-local';

const {
  REACT_APP_GA_MEASUREMENT_ID,
  REACT_APP_AUTH0_APP_DOMAIN,
  REACT_APP_AUTH0_APP_CLIENT_ID,
  REACT_APP_AUTH0_API_AUDIENCE,
  REACT_APP_DOMAIN,
  REACT_APP_RELEASE_VERSION,
  REACT_APP_SENTRY_DSN,
  NODE_ENV,
  REACT_APP_SERVER_PORT,
  REACT_APP_OPEN_CAGE_DATA_API_KEY
} = process.env;

const appName = 'repfabric-analytics-dashboard';
const APP_DOMAIN = REACT_APP_DOMAIN;
const PORT = REACT_APP_SERVER_PORT;

const CONSTS = {
  isDev,
  isProd,
  isProdLocal,
  appName,
  serverName: `${appName}-server`,
  clientName: `${appName}-client`,
  appVersion: REACT_APP_RELEASE_VERSION,
  gaMeasurementId: REACT_APP_GA_MEASUREMENT_ID,
  //apiBaseUrl: `/api/v1`,
  appDomain: isDev || isProdLocal ? `${APP_DOMAIN}:${PORT}` : APP_DOMAIN, // on prod this will be used as no port needed
  appDomainClient: isDev || isProdLocal ? `${APP_DOMAIN}:${PORT}` : APP_DOMAIN,
  appDomainServer: isDev || isProdLocal ? `${APP_DOMAIN}:${PORT}` : APP_DOMAIN,
  port: PORT, // env specific
  nodeEnv: NODE_ENV,
  auth0: {
    appDomain: REACT_APP_AUTH0_APP_DOMAIN, // env specific
    appClientId: REACT_APP_AUTH0_APP_CLIENT_ID,
    apiAudience: REACT_APP_AUTH0_API_AUDIENCE,
    apiOrigin: isDev || isProdLocal ? `${APP_DOMAIN}:${PORT}` : APP_DOMAIN // env specific
  },
  sentryDsn: REACT_APP_SENTRY_DSN,
  themes: {
    light: 'LIGHT',
    oneDark: 'ONE_DARK'
    //unicorn: 'UNICORN'
  },
  apiBaseUrl: '/api',
  serverPort: REACT_APP_SERVER_PORT,
  dateFormat: 'DD-MM-YYYY',
  openCageDataApiKey: REACT_APP_OPEN_CAGE_DATA_API_KEY
};

export default CONSTS;
