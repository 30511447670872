/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import typography from './typography';
import { softShadows, strongShadows } from './shadows';
export { useReportStyles } from './report';
export { GlobalStyles } from './GlobalStyles';

const baseConfig = {
  direction: 'ltr',
  typography,

  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const themeConfigs = (configs) => [
  {
    name: configs.themes.light,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: colors.indigo[600],
        light: colors.indigo[400],
        dark: colors.indigo[800]
      },
      secondary: {
        main: '#5850EC',
        light: '#927dff', // light purple
        dark: '#0026b8'
      },
      tertiary: {
        main: '#ff8a85', // light red (warnings)
        light: '#ffbcb5',
        dark: '#c85a58'
      },
      orange: {
        main: '#ff8a1f', // light orange
        light: '#ffbb53',
        dark: '#c65b00'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows
  },
  {
    name: configs.themes.oneDark,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#8a85ff',
        light: '#bfb5ff',
        dark: '#5558cb'
      },
      secondary: {
        main: '#8a85ff', // purple
        default: '#03dac6', // teal
        light: '#66fff9', // teal
        dark: '#00a896' // teal
      }, //#1de9b6 teal more green default #6effe8 light #00b686 daark
      tertiary: {
        main: '#ff8a85', // light red (warnings)
        light: '#ffbcb5',
        dark: '#c85a58'
      },
      orange: {
        main: '#ff8a1f', // light orange
        light: '#ffbb53',
        dark: '#c65b00'
      },
      error: {
        main: '#f44336'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      }
    },
    shadows: strongShadows
  }
  /*{
    name: configs.themes.unicorn,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d'
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      error: {
        main: '#f44336' // lighter red
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      }
    },
    shadows: strongShadows
  }*/
];

export function createTheme(settings = {}, configs = {}) {
  let themeConfig = themeConfigs(configs).find((theme) => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = createMuiTheme({
    ...baseConfig,
    ...themeConfig,
    ...{ direction: settings.direction }
  });

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}

/*

const useStyles = makeStyles((theme) => ({
  root: {
    '& .ql-toolbar': {
      borderLeft: 'none',
      borderTop: 'none',
      borderRight: 'none',
      borderBottom: `1px solid ${theme.palette.divider}`,
      '& .ql-picker-label:hover': {
        color: theme.palette.secondary.main
      },
      '& .ql-picker-label.ql-active': {
        color: theme.palette.secondary.main
      },
      '& .ql-picker-item:hover': {
        color: theme.palette.secondary.main
      },
      '& .ql-picker-item.ql-selected': {
        color: theme.palette.secondary.main
      },
      '& button:hover': {
        color: theme.palette.secondary.main,
        '& .ql-stroke': {
          stroke: theme.palette.secondary.main
        }
      },
      '& button:focus': {
        color: theme.palette.secondary.main,
        '& .ql-stroke': {
          stroke: theme.palette.secondary.main
        }
      },
      '& button.ql-active': {
        '& .ql-stroke': {
          stroke: theme.palette.secondary.main
        }
      },
      '& .ql-stroke': {
        stroke: theme.palette.text.primary
      },
      '& .ql-picker': {
        color: theme.palette.text.primary
      },
      '& .ql-picker-options': {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        border: 'none',
        boxShadow: theme.shadows[10],
        borderRadius: theme.shape.borderRadius
      }
    },
    '& .ql-container': {
      border: 'none',
      '& .ql-editor': {
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
        color: theme.palette.text.primary,
        '&.ql-blank::before': {
          color: theme.palette.text.secondary
        }
      }
    }
  },
  stepButton: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));
*/
