//

import { createSlice } from '@reduxjs/toolkit';
// import { getAllDataConversionRecords, updateDataConversionRecordById } from 'src/api';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

/*
const setSelectedToGroup = (newGroup) => {
  let selectedRowNodes = gridApi.getSelectedNodes();
  let selectedIds = selectedRowNodes.map(rowNode => rowNode.id;);
  immutableStore = immutableStore.map((dataItem) => {
    let itemSelected = selectedIds.indexOf(dataItem.symbol) >= 0;
    if (itemSelected) {
      return {
        symbol: dataItem.symbol,
        price: dataItem.price,
        group: newGroup,
      };
    } else {
      return dataItem;
    }
  });
  gridApi.setRowData(immutableStore);
};
*/

// ag-grid column defs

/**
 * Repfabric CRM Companies Upload Format
 */

/**
 * This is the company name, or sometimes called account name.
 *
 * There can be NO DUPLICATE company names.
 *
 * A "company" is any external company to your organization that you sell to, collect dollars from, or use to provide services.
 * 
 * [Company Name] [City] *([Street num street type])* *([Location Number])* *([HQ])*

  HQ Would be the parent company of a group

 */

export const companies = [
  {
    field: 'companyName',
    headerName: 'Company Name',
    type: 'String',
    description:
      'Full spelling of company name, capital case, city if needed for unique location, store number if available. (LLC, Corp. etc. removed), all punctuation removed, space between & symbol with capitol on both sides, acronyms need to stay all caps (usually at the beginning) ',
    unique: true
  },
  {
    field: 'type',
    headerName: 'Type', // one of (type list) - custom if needed
    type: 'String',
    description: `The fixed types drive real functionality in Repfabric, versus your custom types which can be added later to fit your particular business model. Fixed types are Principal, Customer, Distributor and External Account. Customer is a company that you sell to as an end user, OEM, design location, etc. for which you get paid commissions. Distributor is a company that facilitates the stock and shipments for the sale of goods to the customer. External Account is an account that is useful information for sales or deals that you want reports on but you never call on them nor sell to them directly. A contract manufacturer is a good example.We strongly suggest that you define your Principals first, then Distributors and External Accounts. Finally, filter what's left to define as Customers.`,
    dropdownItems: ['Principal', 'Distributor', 'External Account', 'Customer'], // Array<string>
    required: true
  },
  {
    field: 'taggedCustomer',
    headerName: 'Tagged Customer ?',
    type: 'String',
    description:
      'For distributors, if Allow Distributor Commission is enabled and a sales team is provided it will be treated as tagged customer even if "Tagged customer" is not set as Y.',
    acceptedValues: ['Y', ''], // blank if not required
    required: false
  },
  {
    field: 'industry',
    headerName: 'Industry',
    type: 'String', // one of (industry list) - custom if needed
    description: '',
    dropdownItems: [''] // Array<string>
  },
  {
    field: 'category',
    headerName: 'Category',
    type: 'string', // one of (category list) - custom if needed
    description: '',
    dropdownItems: [''] // Array<string>
  },
  {
    field: 'street',
    headerName: 'Street 1',
    type: 'formattedAddressWithoutCityStateZip', // [street num] [street type] [street name] [building and or suite number]
    description: `Addresses must match a unique company name that a real sales person or sales team calls on. Correct commissions payout depends upon it.

    For example if you have two branches of a distributor called "Anixter", you should call one "Anixter - NYC" and "Anixter - Albany"   

    Identifying them as such allows sales teams to be compensated individually for commissions they generate at this particular physical address.`
    /*
      npm install parse-address
      var parser = require('parse-address'); 
      var parsed = parser.parseLocation('1005 N Gravenstein Highway Sebastopol CA 95472');

      TODO: Parsed address:
      {
      number: '1005',
      prefix: 'N',
      street: 'Gravenstein',
      type: 'Hwy',
      city: 'Sebastopol',
      state: 'CA',
      zip: '95472' }
  */
  },
  {
    field: 'street2',
    headerName: 'Street 2',
    type: 'formattedAddressWithoutCityStateZip', // [street num] [street type] [street name] [building and or suite number]
    description: ''
  },
  {
    field: 'city',
    headerName: 'City',
    type: 'string',
    description: 'Full spelling of city, capital case'
  },
  {
    field: 'state',
    headerName: 'State',
    type: 'string', // one of (state abbrev) - option for custom if non-US
    description: 'Abbreviation of state'
  },
  {
    field: 'zipCode',
    headerName: 'Zip Code',
    type: 'number|string',
    description: ''
  },
  {
    field: 'country',
    headerName: 'Country',
    type: 'string', // one of (country list)
    description: 'Full country spelling'
  },
  {
    field: 'phone1',
    headerName: 'Phone 1',
    type: 'Phone',
    description: ''
  },
  {
    field: 'phone1Extn',
    headerName: 'Phone 1 Extn',
    type: '',
    description: ''
  },
  {
    field: 'phone2',
    headerName: 'Phone 2',
    type: 'Phone', // library to format (or manual format)
    description: ''
  },
  {
    field: 'phone2Extn',
    headerName: 'Phone 2 Extn',
    type: 'PhoneExtn', // library to format (or manual format)
    description: ''
  },
  {
    field: 'fax',
    headerName: 'Fax',
    type: 'Phone', // library to format
    description: ''
  },
  {
    field: 'region',
    headerName: 'Region',
    type: 'String',
    description: '', // grab all of the regions available, user can add more, but still a dropdown
    dropdownItems: [''] // Array<String>
  },
  {
    field: 'website',
    headerName: 'Website',
    type: '', // make sure it is a valid website, can be used to find dupes if needed
    description: `This field is required for good results from the Repfabric SyncPlus 2-way syncing of your email's contacts.

    For example, if you add a new contact in your email client with the address newcontact@generalelectric.com, that new contact will be added to the company that matches the website with the domain name of generalelectric.com in this field.  
  
    The SyncPlus feature was designed to easily populate a new contact with information it gathers. However, if the sync process does not find generalelectric.com, it will create a new company which then becomes a duplicate. That is why it is important to populate this field for proper syncing.`
  },
  {
    field: 'callPattern',
    headerName: 'Call Pattern',
    type: '',
    description: '', // what is this??
    dropdownItems: ['']
  },
  {
    field: 'holidayCardRequired',
    headerName: 'Holiday Card Required',
    type: '',
    description: '',
    acceptedValues: ['Y', ''] // blank if not required
  },
  {
    field: 'salesTeam',
    headerName: 'Sales Team',
    type: 'string', // if multiple sales reps not named as a team, Sales Rep 1;Sales Rep 2
    description: '',
    dropdownItems: ['']
  },
  {
    field: 'productPotential',
    headerName: 'Product Potential',
    type: '',
    description: '', // are there a finite amount of product potentials??
    dropdownItems: ['']
  },
  {
    field: 'comments',
    headerName: 'Comments',
    type: 'String', // longer string input
    description: ''
  },
  {
    field: 'companyReferenceNo',
    headerName: 'Company Reference No',
    type: 'String',
    description: `Unique to the platform (e.g. Dynamacs, Telenotes), same style as sales team, if multiple are merged, someId1;someId2`
  },
  {
    field: 'isActive',
    headerName: 'Is Active?',
    type: 'String',
    description: 'Any other value than No is considered active',
    acceptedValues: ['No', '']
  },
  {
    field: 'parentCompany',
    headerName: 'Parent Company',
    type: '',
    description: ''
  },
  {
    field: 'poBox',
    headerName: 'PO Box',
    type: 'String', // library for standard formats
    description: ''
  },
  {
    field: 'tags',
    headerName: 'Tags',
    type: 'string',
    description: `Semi colon separated data from any extra fields that the customer wants in the system`
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    type: 'String', // One of the users
    description: '',
    dropdown: ['user1', 'user2']
    /*
      TODO: This will be useful for contacts as well
      npm install humanparser

      const human = require('humanparser');

      const fullName = 'Mr. William R. Hearst, III';
      const attrs = human.parseName(fullName);

      console.log(attrs);

      //produces the following output

      { 
          salutation: 'Mr.',
          firstName: 'William',
          suffix: 'III',
          lastName: 'Hearst',
          middleName: 'R.',
          fullName: 'Mr. William R. Hearst, III'
      }
    */
  },
  {
    field: 'creationDate',
    headerName: 'Creation Date',
    type: 'date', // format MM-DD-YYYY
    description: ''
  },
  {
    field: 'lastModifiedUser',
    headerName: 'Last Modified User',
    type: '',
    description: ''
  },
  {
    field: 'lastModifiedDate',
    headerName: 'Last Modified Date',
    type: '', // format MM-DD-YYYY
    description: ''
  },
  {
    field: 'industries',
    headerName: 'Industries',
    type: '',
    description: ''
  }
];

/**
 * Repfabric CRM Companies Alias Upload Format
 */

export const companyAlias = [
  {
    field: 'aliasName',
    headerName: 'Alias Name',
    type: 'string',
    description: ''
  },
  {
    field: 'city',
    headerName: 'City',
    type: 'string',
    description: ''
  },
  {
    field: 'zip',
    headerName: 'Zip',
    type: 'number|string',
    description: ''
  },
  {
    field: 'internalDivision',
    headerName: 'Internal Division',
    type: '',
    description: ''
  },
  {
    field: 'mfgShipToNum',
    headerName: 'Mfg Ship-to Num',
    type: '',
    description: ''
  },
  {
    field: 'partNumber',
    headerName: 'Part Number',
    type: '',
    description: ''
  },
  {
    field: 'aliasByPartNumber',
    headerName: 'Alias By Part Number?',
    type: '',
    description: '',
    required: false
  },
  {
    field: 'rfCompanyName',
    headerName: 'RF Company Name',
    type: 'string',
    unique: true,
    description: ''
  }
];

const defaultColumnDef = {
  editable: true,
  sortable: true,
  resizable: true,
  filter: true,
  flex: 1,
  minWidth: 100,
  enableRowGroup: true,
  enablePivot: true,
  enableValue: true
};

const dataConversionInitialState = {
  dataConversionRecordsByType: {},
  formats: {},
  files: [{ fileName: 'blank', fileId: '' }],
  currentFile: { fileName: 'blank', fileId: '' },
  defaultColDef: defaultColumnDef,
  bottomOptions: {
    alignedGrids: [],
    defaultColDef: defaultColumnDef
  },
  filesById: {
    blank: {
      field: '',
      lastModified: '',
      size: '',
      type: '',
      columnMap: [
        {
          rfField: 'salesReps',
          rfHeaderName: 'Sales Reps',
          fileField: '',
          fileHeaderName: ''
        }
      ],
      columns: [
        {
          field: 'salesReps',
          headerName: 'Sales Reps',
          children: [
            { headerName: 'Sales Rep 1', field: 'salesRep1' },
            { headerName: 'Sales Rep 2', field: 'salesRep2' },
            { headerName: 'Sales Rep 3', field: 'salesRep3' }
          ]
        }
      ],
      rowsById: {},
      metadata: {}
    }
  },
  columnsToMatch: [
    {
      finalFileTitle: `Superfile-${moment().format()}`,
      finalFileId: `Superfile-${moment().format()}`,
      columns: {
        columnsToCombine: [{ fileTitle: '', fileId: '', columns: [{}] }]
      }
    }
  ],
  companies,
  companyAlias,
  outputFile: [],
  hasFiles: false,
  isLoading: false,
  error: null
};
/*
function startLoading(state) {
  state.hasFetchedData = false;
  state.isLoading = true;
}

function loadingFailed(state, action) {
  state.isLoading = false;
  state.error = action.payload;
}
*/
const dataConversion = createSlice({
  name: 'dataConversion',
  initialState: dataConversionInitialState,
  reducers: {
    //getDataConversionRecordsStart: startLoading,
    //updateDataConversionRecordStart: startLoading,
    /*getDataConversionRecordsSuccess(state, { payload }) {
      const dataConversionRecords = payload;
      dataConversionRecords.forEach((dataConversionRecords) => {
        state.dataConversionRecordsByType[dataConversionRecords.type] = dataConversionRecords;

        // Set temp record for saving later
        state.tempDataConversionRecordsByType[dataConversionRecords.type] = {
          rates: dataConversionRecords.rates,
          firstSixMonths: {
            rates: dataConversionRecords.firstSixMonths.rates
            //ranges: dataConversionRecords.firstSixMonths.ranges
          }
        }; 
      });
      state.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },*/
    setCurrentFile(state, { payload }) {
      console.log('currentFile', payload);
      state.currentFile = payload;
    },
    setFileColumnMapItem(state, { payload }) {
      const { fileId, columnMapItem } = payload;

      state.filesById[fileId].columnMapById[columnMapItem.colId] = {
        ...state.filesById[fileId].columnMapById[columnMapItem.colId],
        rfField: columnMapItem.rfField,
        rfHeaderName: columnMapItem.rfHeaderName
      };
    },
    setFileRecords(state, { payload }) {
      //console.log('payload', payload);
      const { columns, fileMeta, rows } = payload;

      /*
      {
      title: '',
      lastModified: '',
      size: '',
      type: '',
      columns: [''],
      rows: [{}],
      metadata: {}
    } */
      const fileId = uuidv4();
      if (!rows[0].rowId) {
        console.log(`Rows do not have uuid: row`, rows[0], 'creating them');
      }
      const rowsWithId = rows.map((row) => ({
        ...row,
        rowId: uuidv4()
      }));

      const rowsById = {};

      rowsWithId.forEach((row) => {
        rowsById[row.rowId] = row;
      });

      const columnMapWithId = columns.map((column) => ({
        fileField: column.field,
        fileHeaderName: column.headerName,
        rfHeaderName: '',
        rfField: '',
        colId: column.field
      }));
      const columnMapById = {};

      columnMapWithId.forEach((colMapItem) => {
        columnMapById[colMapItem.colId] = colMapItem;
      });

      delete state.filesById['blank'];

      state.filesById[fileId] = {
        name: fileMeta.name,
        lastModified: fileMeta.lastModified,
        size: fileMeta.size,
        type: fileMeta.type,
        columns: columns,
        columnMap: columns.map(({ field, headerName }) => ({
          fileField: field,
          fileHeaderName: headerName,
          rfHeaderName: '',
          rfField: '',
          colId: field
        })),
        columnMapById,
        rowsById: rowsById,
        metadata: {}
      };

      const files = state.files.filter(({ fileName }) => fileName !== 'blank');
      //const currentFile = currentFile;
      state.files = [...files, { fileName: fileMeta.name, fileId }];
      if (!state.hasFiles) {
        console.log('redux updating current file', state.currentFile);
        state.currentFile = { fileName: fileMeta.name, fileId };
      }
      //console.log('redux updating current file outside of if statement', state.currentFile);

      /*const currentRecord = state.dataConversionRecordsByType[type];
      if (firstSixMonths) {
        state.tempDataConversionRecordsByType[type] = {
          rates: currentRecord.rates,
          firstSixMonths: {
            rates: [0, newMidValue, newHighValue]
            //ranges: currentRecord.firstSixMonths.ranges
          }
        };
      } else {
        state.tempDataConversionRecordsByType[type] = {
          rates: [0, newMidValue, newHighValue],
          firstSixMonths: {
            rates: currentRecord.firstSixMonths.rates
          }
        };
      }*/

      state.hasFiles = true;
      state.isLoading = false;
      state.error = null;
    }
    /*setTempDataConversionRecord(state, { payload }) {
      const { type, firstSixMonths, newMidValue, newHighValue } = payload;
      const currentRecord = state.dataConversionRecordsByType[type];
      if (firstSixMonths) {
        state.tempDataConversionRecordsByType[type] = {
          rates: currentRecord.rates,
          firstSixMonths: {
            rates: [0, newMidValue, newHighValue]
            //ranges: currentRecord.firstSixMonths.ranges
          }
        };
      } else {
        state.tempDataConversionRecordsByType[type] = {
          rates: [0, newMidValue, newHighValue],
          firstSixMonths: {
            rates: currentRecord.firstSixMonths.rates
          }
        };
      }

      state.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },
    updateDataConversionRecordSuccess(state, { payload }) {
      // previous
      const { updates, current } = payload;
      if (updates?.nModified === 1) {
        state.dataConversionRecordsByType[current.type] = current;
        state.tempDataConversionRecordsByType[current.type] = {
          rates: current.rates,
          firstSixMonths: {
            rates: current.firstSixMonths.rates
            //ranges: current.firstSixMonths.ranges
          }
        };
      }
      state.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },*/
    //getDataConversionRecordsFailure: loadingFailed,
    //updateDataConversionRecordFailure: loadingFailed
    //setColumnMap(state, { payload }) {}
  }
});

export const { setFileRecords, setCurrentFile, setFileColumnMapItem } = dataConversion.actions;

export default dataConversion.reducer;

/*
export const fetchDataConversionRecords = () => async (dispatch) => {
  try {
    dispatch(getDataConversionRecordsStart());
    const dataConversionRecords = await getAllDataConversionRecords();
    console.log('data conversion records redux', dataConversionRecords);
    dispatch(getDataConversionRecordsSuccess(dataConversionRecords));
  } catch (err) {
    dispatch(getDataConversionRecordsFailure(err.toString()));
  }
};

export const updateDataConversionRecord = ({ id, update }) => async (dispatch) => {
  try {
    dispatch(updateDataConversionRecordStart());
    const dataConversionRecords = await updateDataConversionRecordById(id, update);
    dispatch(updateDataConversionRecordSuccess(dataConversionRecords));
  } catch (err) {
    dispatch(updateDataConversionRecordFailure(err.toString()));
  }
};*/
/*
export const saveMathTable = () => async (dispatch) => {
  try {
    dispatch(getMathTableStart());
    const dataConversion = await getMathTable();
    dispatch(getMathTableSuccess(dataConversion));
  } catch (err) {
    dispatch(getMathTableFailure(err.toString()));
  }
};
*/
