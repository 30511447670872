import React from 'react';
import * as Sentry from '@sentry/react';
//import ModalWrapper from './ModalWrapper';
import SplashScreen from 'src/components/SplashScreen';
import CONSTS from 'src/constants';

class ErrorBoundary extends React.Component {
  state = { eventId: null, message: 'Test message', error: '' };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    return (
      <>
        {CONSTS.isDev ? (
          <>{this.props.children}</>
        ) : (
          <Sentry.ErrorBoundary fallback={SplashScreen} showDialog>
            {this.props.children}
          </Sentry.ErrorBoundary>
        )}
      </>
    );
  }
}

export default ErrorBoundary;
