import React from 'react';
import { Helmet } from 'react-helmet';

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

function GoogleAnalytics() {
  return (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`} />
      <script
        type="text/javascript"
        async
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDbRkWjAL7KgTe7XIZwhy-CtnoKtRTFIX8&libraries=places"
      />
      {/*<script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
        `}
        </script>*/}
    </Helmet>
  );
}

export default GoogleAnalytics;
