import { createSlice } from '@reduxjs/toolkit';
import { getUserById, getUsers } from 'src/api';

const accountsInitialState = {
  users: {
    byId: {
      '104584792854239816468': {
        created_at: '2020-06-02T16:20:47.699Z',
        email: 'mattoldfieldweb@gmail.com',
        email_verified: true,
        family_name: 'Oldfield',
        given_name: 'Matthew',
        identities: [
          {
            provider: 'google-oauth2',
            access_token: '',
            expires_in: 3599,
            user_id: '104584792854239816468',
            connection: 'google-oauth2',
            isSocial: true
          }
        ],
        locale: 'en',
        name: 'Matthew Oldfield',
        nickname: 'mattoldfieldweb',
        picture:
          'https://lh3.googleusercontent.com/a-/AOh14Gjs-lKTXvNN8Pq1tcFnS4XnG31tkh5042fYgnkoWcw=s96-c',
        updated_at: '2020-11-23T06:25:54.990Z',
        user_id: 'google-oauth2|104584792854239816468',
        user_metadata: {
          some: 'user metadata test'
        },
        last_login: '2020-11-23T06:25:54.990Z',
        last_ip: '35.141.157.68',
        logins_count: 58,
        app_metadata: {
          role: 'super_admin'
        }
      }
    },
    allIds: ['104584792854239816468']
  },
  currentPageUsers: [],
  currentUser: { hasFetchedData: false },
  pageCount: 0,
  pageLinks: {},
  hasFetchedData: false,
  isLoading: false,
  error: null
};
/*
function startLoading(state) {
  state.hasFetchedData = false;
  state.isLoading = true;
}

function loadingFailed(state, action) {
  state.isLoading = false;
  state.error = action.payload;
}
*/
function startUserLoading(state) {
  state.currentUser.hasFetchedData = false;
  state.currentUser.isLoading = true;
}

function loadingUserFailed(state, action) {
  state.currentUser.isLoading = false;
  state.currentUser.error = action.payload;
}

function startUsersLoading(state) {
  state.hasFetchedData = false;
  state.isLoading = true;
}

function loadingUsersFailed(state, action) {
  state.isLoading = false;
  state.error = action.payload;
}

/*
function setCurrentUser(state, action) {
  state.currentUser = action.payload;
}*/

const accounts = createSlice({
  name: 'accounts',
  initialState: accountsInitialState,
  reducers: {
    getUserStart: startUserLoading,
    getUsersStart: startUsersLoading,
    getUserSuccess(state, { payload }) {
      state.currentUser = payload;

      state.currentUser.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },
    getUsersSuccess(state, { payload }) {
      let allIds = [];

      payload.users.forEach((user) => {
        const userId = user.user_id.split('|')[1];
        state.users.byId[userId] = user;
        allIds = [...allIds, userId];
      });
      state.users.allIds = allIds;

      state.hasFetchedData = true;
      state.isLoading = false;
      state.error = null;
    },
    getUserFailure: loadingUserFailed,
    getUsersFailure: loadingUsersFailed
  }
});

export const {
  getUserStart,
  getUserSuccess,
  getUserFailure,
  getUsersStart,
  getUsersSuccess,
  getUsersFailure
} = accounts.actions;

export default accounts.reducer;

export const fetchUsers = () => async (dispatch) => {
  try {
    dispatch(getUsersStart());
    const users = await getUsers();
    dispatch(getUsersSuccess(users));
  } catch (err) {
    dispatch(getUsersFailure(err.toString()));
  }
};

export const fetchUserById = (userId) => async (dispatch) => {
  try {
    dispatch(getUserStart());
    const user = await getUserById(userId);
    // console.log('user from fetch user by id', user);
    dispatch(getUserSuccess(user));
  } catch (err) {
    dispatch(getUserFailure(err.toString()));
  }
};
