import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
// import { getAllDataConversionRecords, updateDataConversionRecordById } from 'src/api';
// import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const projectsAdapter = createEntityAdapter();

const projectsSlice = createSlice({
  name: 'projects',
  initialState: projectsAdapter.getInitialState({
    ids: ['5e8dcf105a6732b3ed82cf7a'],
    entities: {
      '5e8dcf105a6732b3ed82cf7a': {
        id: '5e8dcf105a6732b3ed82cf7a',
        title: 'Example Data Project',
        media: '/static/images/projects/project_3.png',
        userId: '104584792854239816468',
        instanceName: 'demo',
        companyName: 'Repfabric',
        technology: 'React',
        isLiked: true,
        likes: 57,
        rating: 3,
        subscribers: 5,
        updatedAt: moment().subtract(24, 'minutes').toDate().getTime()
      }
    },
    isLoading: false
  }),
  reducers: {
    projectsAddOne: projectsAdapter.addOne,
    projectsAddMany: projectsAdapter.addMany,
    projectUpdate: projectsAdapter.updateOne,
    projectRemove: projectsAdapter.removeOne
  }
});

export const {
  projectsAddOne,
  projectsAddMany,
  projectUpdate,
  projectRemove
} = projectsSlice.actions;

export default projectsSlice.reducer;
